<template>
  <main>
    <NavBar current-page="home"/>

    <TextCard top-id="logo-card" paragraph-enabled="false">
      <img class="nav-item" id="navbar-logo-img" src="@/assets/logo.svg" alt="Logo">
      <h1 style="font-size: 3em; padding-top: 24px; margin:auto; text-align: center">KABA 2022</h1>
      <h2 class="h-50" style="margin: auto; text-align: center">29. April</h2>
    </TextCard>

    <TextCard paragraph-enabled="false">
      <h1><a href="https://www.flickr.com/photos/kaba2022/" class="link-light"><div style="text-align: center; color: #FFD885; font-size: 2.5em;">Fotos</div></a></h1>
    </TextCard>

    <TextCard top-id="trailer-card" paragraph-enabled="false">
      <TrailerVideoPlayer></TrailerVideoPlayer>
    </TextCard>

    <TextCard paragraph-enabled="false" header="Acts">
      <div class="container p-md-4" style="margin: auto; padding: 6px 24px 24px">
        <div class="row"
             style="margin:auto; width: fit-content; left: 5px; right: 5px; display: flex; align-items: baseline; justify-content: space-between;">
          <div class="col-lg-3 col-md-6 mb-4 mb-md-0"
               style="width: 50%; min-width: 231px; max-width: 557px; margin: 0 auto auto">
            <h4 class="act-header">Kanti-Band</h4>
            <img class="act-img" src="@/assets/acts/kanti_band.jpeg" alt="Kanti Band">
          </div>
          <div class="col-lg-3 col-md-6 mb-4 mb-md-0"
               style="width: 50%; min-width: 231px;  max-width: 557px;margin: 0 auto auto">
            <h4 class="act-header">Superflip</h4>
            <img class="act-img" src="@/assets/acts/superflip.jpeg" alt="Superflip">
          </div>
        </div>
        <div class="row"
             style="margin: auto; width: fit-content; left: 5px; right: 5px; padding-top: 24px; display: flex; align-items: baseline; justify-content: space-between;">
          <div class="col-lg-3 col-md-6 mb-4 mb-md-0"
               style="width: 50%; min-width: 231px;  max-width: 557px;margin: 0 auto auto">
            <h4 class="act-header">Bliss Pills</h4>
            <img class="act-img" src="@/assets/acts/bliss_pills.jpeg" alt="Bliss Pills">
          </div>
          <div class="col-lg-3 col-md-6 mb-4 mb-md-0"
               style="width: 50%; min-width: 231px;  max-width: 557px; margin: 0 auto auto">
            <h4 class="act-header">DANZ w/ Friendz</h4>
            <img class="act-img" src="@/assets/acts/DANZ.jpeg" alt="DANZ w/ Friendz">
          </div>
        </div>
      </div>
    </TextCard>

    <TextCard header="Programm">
      <table class="table-dark" style="width: 100%">
        <tr class="row">
          <th scope="col" class="col">
            Act
          </th>
          <th scope="col" class="col">
            Uhrzeit
          </th>
        </tr>
        <tr class="row">
          <td class="col">Türöffnung</td>
          <td class="col">20:00</td>
        </tr>
        <tr class="row">
          <td class="col">Showblock</td>
          <td class="col">20:45 - 21:15</td>
        </tr>
        <tr class="row">
          <td class="col">Kanti-Band</td>
          <td class="col">21:30 - 22:00</td>
        </tr>
        <tr class="row">
          <td class="col">Superflip</td>
          <td class="col">22:15 - 23:15</td>
        </tr>
        <tr class="row">
          <td class="col">Bliss Pillss</td>
          <td class="col">23:30 - 00:00</td>
        </tr>
        <tr class="row">
          <td class="col">DANZ w/ Friendz</td>
          <td class="col">00:15 - 03:00</td>
        </tr>
      </table>
    </TextCard>
    <TextCard>
      Am 29. April rollen wir für euch den roten Teppich aus. Passend zu unserem Thema könnt ihr in dieser Nacht euch
      fühlen, wie ein Celebrity. Ob in Anzug oder Kleid, jeder Mensch kann auf seine Art und Weise strahlen.
      <br>
      <br>
      Der Eintritt kostet 25.- CHF und wir bitten dich, deine ID am Abend mitzunehmen.
    </TextCard>
  </main>

  <FooterDiv></FooterDiv>
</template>


<script>
import NavBar from "@/components/NavBar";
import TextCard from "@/components/TextCard";
import TrailerVideoPlayer from "@/components/Trailer";
import FooterDiv from "@/components/Footer";

export default {
  name: 'HomePage',
  components: {
    FooterDiv,
    TrailerVideoPlayer,
    NavBar,
    TextCard
  }
}
</script>

<style>

.act-header {
  text-align: center;
}

.act-img {
  display: flex;
  /*justify-content: space-between;*/
  width: 100%;
  align-content: center;
}

main {
  max-width: 1300px;
  margin: auto;
  overflow-x: hidden;
}

#trailer-video {
  margin: 7.5px;
  margin-bottom: 6.5px;
}

#trailer-card {
  padding: 1px 0 0;
}

.col {
  /*color: #c3a666;*/
}

#logo-card {
  background-color: transparent;
}

/*.info-card {*/
/*  margin: 20px 10px;*/
/*  border-radius: 15px;*/

/*  color: #c3a666;*/
/*  text-align: justify;*/
/*  padding: 1.4em 1.2em;*/
/*}*/
</style>
