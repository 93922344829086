<template>
  <section class="">
    <!-- Footer -->
    <footer style="background-color: #303030" class="bg-secondary text-white text-center">
      <!-- Grid container -->
      <div class="container p-4" style="margin: auto">
        <!--Grid row-->

        <div class="row" style="margin: auto; display: flex; align-items: baseline; justify-content: center;">
          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4 mb-md-0" style="width: fit-content">
            <h5 class="text-uppercase" style="text-align: center;">Hauptsponsoren</h5>

            <ul class="list-unstyled mb-0">
              <li class="main-sponsor">
                <a href="https://www.glarnersach.ch/willkommen-bei-der-glarnersach.html"><img class="main-sponsor-logo"
                                                                                              src="@/assets/sponsors/hauptsponsoren/glarner_sach.png"
                                                                                              alt="Glarner Sach"></a>
              </li>
              <li class="main-sponsor">
                <a href="https://www.kfn.ch/"><img class="main-sponsor-logo"
                                                   src="@/assets/sponsors/hauptsponsoren/kfn.png" alt="KFN"></a>
              </li>
              <li class="main-sponsor">
                <a href="https://www.raiffeisen.ch/">
                  <div id="raiffeisen-logo" style="background-color: white">
                    <img class="main-sponsor-logo"
                         src="@/assets/sponsors/hauptsponsoren/raiffeisen.png"
                         alt="Raiffeisen" style="max-width: 221px; max-height: 32.7px">
                  </div>
                </a>
              </li>
              <li class="main-sponsor">
                <!--                get the website link-->
                <a href=""><img class="main-sponsor-logo" src="@/assets/sponsors/hauptsponsoren/route66.png"
                                alt="Route 66"></a>
              </li>
            </ul>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4 mb-md-0" style="margin: 0 auto auto auto; width: fit-content">
            <h5 class="text-uppercase mb-0" style="text-align: center">Co Sponsoren</h5>

            <ul class="list-unstyled">
              <li class="main-sponsor">
                <a href="https://www.2weeks.ch/"><img class="co-sponsor-logo" style="max-height: 45.9px"
                                                      src="@/assets/sponsors/co_sponsoren/2weeks.png"
                                                      alt="2weeks.ch"></a>
              </li>
              <li class="main-sponsor">
                <a href="https://www.botty.ch/"><img class="weitere-sponsor-logo"
                                                     src="@/assets/sponsors/weitere_sponsoren/botty.png"
                                                     alt="Botty"></a>
              </li>
              <li class="main-sponsor">
                <a href="https://www.lesestoff.ch/"><img class="co-sponsor-logo" style="max-height: 125px"
                                                         src="@/assets/sponsors/co_sponsoren/lesestoff.png"
                                                         alt="lesestoff.ch"></a>
              </li>
              <li class="main-sponsor">
                <a href="https://www.walhalla-weine.ch/startseite.html"><img class="co-sponsor-logo"
                                                                             src="@/assets/sponsors/co_sponsoren/walhalla.png"
                                                                             alt="Walhalla"></a>
              </li>
            </ul>
          </div>

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4 mb-md-0" style="width: fit-content">
            <h5 class="text-uppercase mb-0" style="text-align: center;">Weitere Sponsoren</h5>

            <ul class="list-unstyled">
              <li class="main-sponsor">
                <a href="https://www.apec.ch/" class="link-light">APEC DIFFUSION</a>
              </li>
              <li class="main-sponsor">
                <a href="https://www.belvedere-am.com/" class="link-light">Belvédère Asset Management AG</a>
              </li>
              <li class="main-sponsor">
                <a href="https://flyerfox.ch/" class="link-light">FlyerFox</a>
              </li>
              <li class="main-sponsor">
                <a href="https://glkb.ch/" class="link-light">Glarner Kantonal Bank</a>
              </li>
              <li class="main-sponsor">
                <a href="https://www.stm-ag.ch/" class="link-light">Stöckli Metall AG</a>
              </li>
              <li class="main-sponsor">
                <a href="https://www.tbglarus.ch/" class="link-light">Technische Betriebe Glarus</a>
              </li>
              <li class="main-sponsor">
                <a href="https://uau.vision/" class="link-light">UAU</a>
              </li>
            </ul>
          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </div>
      <!-- Grid container -->

      <div class="">
        <!--Grid column-->
        <div class="col-lg-6 col-md-12 mb-4 mb-md-0" style="margin: auto">
          <h5 class="text-uppercase">Kontakt</h5>

          <p>
            Kaba Chefin: <a class="link-light" href="mailto: sukeyna.corbaci@stud.schulegl.ch">sukeyna.corbaci@stud.schulegl.ch</a><br>
            Instagram: <a class="link-light" href="https://www.instagram.com/kaba.22.gl/">kaba.22.gl</a><br>
            Webseite: <a class="link-light" href="mailto: gianlaager@outlook.com">gianlaager@outlook.com</a><br>
          </p>
        </div>
        <!--Grid column-->
      </div>
      <div style="margin-bottom: 36px"></div>
      <!-- Copyright -->
      <div class="text-center" style="background-color: rgba(0, 0, 0, 0.2);">
        Webseite von Gian Laager
        <p class="text-secondary">
          Falls etwas nicht funktionieren sollte, können Sie mir eine <a class="link-secondary"
                                                                        href="mailto: gianlaager@outlook.com">Email</a>
          schreiben.
        </p>
      </div>
      <!-- Copyright -->
    </footer>
    <!-- Footer -->
  </section>
</template>

<script>
export default {
  name: "FooterDiv"
}
</script>

<style scoped>
.text-white {
  color: #bfb5a9 !important;
}

.bg-secondary {
  background-color: #16191c !important;
}

.main-sponsor {
  padding: 3px;
}

.main-sponsor-logo {
  height: fit-content;
  max-width: 235px;
  max-height: 100px;
  aspect-ratio: initial;
}

.co-sponsor-logo {
  height: fit-content;
  max-width: 235px;
  max-height: 100px;
}

.weitere-sponsor-logo {
  height: fit-content;
  max-width: 235px;
  max-height: 100px;
}

#raiffeisen-logo {
  padding: 7px;
}
</style>