<template>
  <div class="embed-responsive embed-responsive-16by9" id="trailer-video">
    <video controls poster="@/assets/trailer_poster.png">
      <source src="/trailer.mp4" type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  name: "TrailerVideoPlayer"
}
</script>

<style scoped>
 video {
   width: 100%;
   height: auto;
 }
</style>