<template>
  <div class="info-card" v-bind:id="topId">
    <h2 v-if="header !== ''">{{ header }}</h2>
    <p v-if="paragraphEnabled !== 'false'">
      <slot></slot>
    </p>

    <slot v-else></slot>
  </div>
</template>


<script>
export default {
  name: 'TextCard',
  props: {
    header: String,
    topId: String,
    paragraphEnabled: String
  }
}
</script>

<style>
.info-card {
  margin: 20px 10px;
  border-radius: 15px;
  color: #c3a666;
  background: #16191b;
  text-align: justify;
  padding: 1.4em 1.2em;
}
</style>
